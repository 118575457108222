import { FC, useMemo } from 'react';
import User from '../../../models/User';
import { ImageSize, ProfileSizeMap } from './ProfileAvatar';
import { useImageCache } from '../../../contexts/ImageCacheContext';
import Tooltip from '../Tooltip';
import { useCurrentUser } from '../../../global-state/Auth';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import { useGetRandomProfilePicture } from '../../../hooks/useRandomProfilePicture';

type Props = {
  name?: string;
  users: User[];
  size?: ImageSize;
  withMargin?: boolean;
};

const isLocal = location.host.indexOf('localhost') !== -1;

const GroupAvatar: FC<Props> = (props) => {
  const { name, users, size = ImageSize.M, withMargin } = props;
  const { getImage } = useImageCache();
  const currentUser = useCurrentUser((x) => x.value);
  const getRandomProfilePicture = useGetRandomProfilePicture();

  const userImages = useMemo<({ id: string; type: 'image' | 'fallback'; data: string | null; fullName: string } | null)[]>(() => {
    return users
      .filter((x) => x.userImageId)
      .map((x) => ({ id: x.id, type: 'image' as const, data: getImage(x.userImageId!), fullName: x.fullName }));
  }, [getImage, users]);

  const fallbacks = useMemo(() => {
    return users
      .filter((x) => !x.userImageId)
      .map((x) => ({ id: x.id, type: 'fallback' as const, data: `${x.firstName[0]}${x.lastName[0]}`, fullName: x.fullName }));
  }, [users]);

  const data = useMemo(() => {
    let source = userImages.concat(fallbacks);
    if (isLocal) {
      source = users.map((x) => ({
        id: x.id,
        type: 'image' as const,
        data: x.userImageId ? getImage(x.userImageId) : getRandomProfilePicture(x.id),
        fullName: x.fullName,
      }));
    }

    return source.concat([null, null, null, null]).slice(0, 4);
  }, [fallbacks, getImage, getRandomProfilePicture, userImages, users]);

  const sizeCss = ProfileSizeMap[size ?? ImageSize.M].image;
  const marginCss = withMargin ? '-ml-2' : '';
  const hoverCss = `[&:is([data-user-count]):not([data-user-count="0"]):not([data-user-count="1"])]:hover:-mt-[0.3rem] transition-all duration-300 ease-in-out hover:z-20 `;

  return (
    <Tooltip text={name}>
      {(tooltip) => (
        <div
          {...tooltip}
          {...dataAttributeProps(props)}
          className={`z-0 grid grid-cols-2 grid-rows-2 items-center justify-center gap-[2px] overflow-clip rounded-full border border-white bg-white ${sizeCss} ${marginCss} ${hoverCss}`}
        >
          {data.map((x, i) => (
            <Tooltip key={i} text={!name && x?.fullName}>
              {(tooltip) => (
                <div {...tooltip} className="h-full w-full">
                  {x?.type === 'image' ? (
                    <div
                      key={i}
                      className="h-full w-full"
                      style={{
                        backgroundImage: `url(${x.data ?? ''})`,
                        backgroundSize: '200% 200%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPositionX: i % 2 === 0 ? '0%' : '100%',
                        backgroundPositionY: i < 2 ? '0%' : '100%',
                      }}
                    />
                  ) : (
                    <div
                      className={`text-dpm-12 h-full w-full select-none text-center text-white ${currentUser?.id === x?.id ? 'bg-accent-1' : x ? 'bg-[#C6A97D]' : 'bg-gray-4'}`}
                    >
                      {x?.data}
                    </div>
                  )}
                </div>
              )}
            </Tooltip>
          ))}
        </div>
      )}
    </Tooltip>
  );
};

export default GroupAvatar;
