import { FC, useMemo, useState } from 'react';
import User from '../../models/User';
import ProfileAvatar, { AvatarRole, ImageSize } from '../shared/profile-image/ProfileAvatar';
import { useTranslation } from 'react-i18next';
import { ClientFormUserOnly } from '../../models/ClientFormUser';
import { Option } from '../Option';
import { SelectListMenu } from '../shared/SelectListMenu';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import useAvatarRoleNames from '../../hooks/useAvatarRoleNames';
import { useCurrentUser } from '../../global-state/Auth';
import { ClientUserGrouping } from '../../hooks/useFetchActiveClientGroups';
import LanguageUtils from '../../utils/LanguageUtils';
import GroupAvatar from '../shared/profile-image/GroupAvatar';

type ProfileImageStackProps = {
  users?: (ClientFormUserOnly | ClientUserGrouping)[];
  size?: ImageSize;
  caption?: string;
  displayLimit?: number;
};

const ProfileImageStack: FC<ProfileImageStackProps> = (props) => {
  const { users: items = [], size = ImageSize.S, caption, displayLimit = 3 } = props;
  const currentUser = useCurrentUser((x) => x.value);
  const { t } = useTranslation(['common']);
  const [showMore, setShowMore] = useState(false);

  let moreSizeCss = 'w-12 h-12 text-dpm-14';

  switch (size) {
    case ImageSize.XS:
      moreSizeCss = 'min-w-8 w-8 h-8 text-dpm-12';
      break;
    case ImageSize.S:
      moreSizeCss = 'min-w-10 w-10 h-10 text-dpm-12';
      break;
    default:
      moreSizeCss = 'min-w-12 w-12 h-12 text-dpm-14';
      break;
  }

  // Group users by id and accumulate their roles, then sort to place current user first
  const groupedItems = useMemo(() => {
    const itemMap = new Map<
      string,
      { id: string; name: string } & (
        | {
            user: ClientFormUserOnly;
            roles: AvatarRole[];
          }
        | { group: ClientUserGrouping }
      )
    >();

    items.forEach((item) => {
      if (!item?.id) return;

      if (!itemMap.has(item.id)) {
        if ('users' in item) {
          itemMap.set(item.id, { id: item.id, group: item, name: LanguageUtils.getTranslation('name', item.translations ?? {}) ?? '' });
        } else {
          itemMap.set(item.id, { id: item.id, user: item, roles: [], name: `${item.firstName} ${item.lastName}`.trim() || item.email! || '' });
        }
      }

      const itemData = itemMap.get(item.id!);
      if (itemData && 'roles' in itemData) {
        const user = item as ClientFormUserOnly;
        const existingRoleIndex = itemData.roles.findIndex((r) => r.role === user.role && r.requiresAction === !!user.requiresAction);
        if (existingRoleIndex === -1) {
          itemData.roles.push({
            role: user.role!,
            requiresAction: !!user.requiresAction,
            roleOrder: user.sortOrder,
            isStepRole: !!user.formSectionId,
          });
        }
      }
    });

    const groupedUsersArray = Array.from(itemMap.values());
    return groupedUsersArray.sort((a, b) => {
      // Sort `currentUser.id` to be first
      if (a.id === currentUser?.id) {
        return -1;
      }
      if (b.id === currentUser?.id) {
        return 1;
      }

      // Else sort by name
      return a.name.localeCompare(b.name);
    });
  }, [items, currentUser?.id]);

  const itemsToDisplay = groupedItems.slice(0, displayLimit);
  const remainingItems = groupedItems.slice(displayLimit);

  const RemainingUsersListRenderer = useMemo(() => {
    return function ListRenderer(props: Option<string, string | number>) {
      const itemIndex = remainingItems.findIndex((x) => x.id === props.id);
      const remItem = remainingItems?.[itemIndex];
      const roleNames = useAvatarRoleNames('roles' in remItem ? (remItem?.roles ?? []) : [], t);

      if (!remItem) {
        return null;
      }

      return (
        <div key={remItem.id} className={`flex p-1 ${itemIndex === remainingItems.length - 1 ? 'border-b-0' : 'border-b'} border-gray-6`}>
          <div className="px-1">
            {'user' in remItem ? (
              <ProfileAvatar data-cy={`user-${remItem.id}`} user={remItem.user as User} roles={remItem.roles} size={size} />
            ) : (
              <GroupAvatar data-cy={`tag-${remItem.id}`} users={remItem.group.users} size={size} />
            )}
          </div>
          <div className="px-1">
            <span className="block">{remItem.name}</span>
            <span className="text-dpm-12 text-gray-2">{roleNames}</span>
          </div>
        </div>
      );
    };
  }, [remainingItems, size, t]);

  return (
    <div className="flex h-full" data-cy="profile-stack">
      <div className="flex flex-col self-center">
        <span className={`text-dpm-12 text-center font-medium ${caption ? 'mb-3' : ''}`} data-cy="caption" aria-label={t('aria-label.caption')}>
          {caption}
        </span>
        <div className="profile-image-stack relative flex justify-items-center self-center">
          {groupedItems.length === 0 && <ProfileAvatar data-cy="no-users" data-user-count={0} size={size} />}
          {itemsToDisplay.map((item, index) => {
            if ('group' in item) {
              return (
                <GroupAvatar
                  key={item.id}
                  data-cy={`tag-${item.id}`}
                  data-user-count={groupedItems.length}
                  name={item.name}
                  users={item.group.users}
                  withMargin={index > 0 && groupedItems.length > 1}
                  size={size}
                />
              );
            }

            return (
              <div key={item.id} className="relative">
                <ProfileAvatar
                  data-cy={`user-${item.id}`}
                  data-user-count={groupedItems.length}
                  user={item.user as User}
                  roles={item.roles}
                  size={size}
                  withMargin={index > 0 && groupedItems.length > 1}
                />
              </div>
            );
          })}
          {remainingItems.length > 0 && (
            <>
              <SelectListMenu
                isOpen={showMore}
                options={remainingItems.map((item) => {
                  return {
                    id: item.id || '',
                    value: item.name,
                    text: item.name,
                  };
                })}
                customListItemRenderer={RemainingUsersListRenderer}
                onBlur={() => setShowMore(false)}
                className="border-none text-left"
                width="w-fit"
              >
                {(triggerProps) => (
                  <div {...triggerProps} className="relative">
                    <div
                      {...mouseAndKeyboardCallbackProps((e) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        setShowMore(!showMore);
                      })}
                      className={`bg-gray-5 relative -ml-2 -mt-[1px] inline-flex items-center justify-center rounded-full border-2 border-white p-1 pt-[5px] font-medium text-black ${moreSizeCss} cursor-pointer`}
                    >
                      +{remainingItems.length}
                    </div>
                  </div>
                )}
              </SelectListMenu>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { ImageSize, ProfileImageStack };
